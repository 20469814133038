html,
body {
	padding: 0;
	margin: 0;
	position: relative;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	// background: $background-color url(https://res.cloudinary.com/squidge-inc/image/fetch/f_auto/https://t-b-s.netlify.com/assets/rebel.png) repeat center top;
	background: $background-color;
	font-size: $base-font-size;
	color: $base-text-color;
	line-height: $base-line-height;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

.global-wrapper {
	overflow: hidden;
	position: relative;
	z-index: 5;
}

a {

}

h1,
h2,
h3,
h4 {
	line-height: 1em;
	padding: 0.5em 0 0.35em 0;

	& + p {
		margin-top: 0;
	}
}

a:not(.global-nav__link):not(.button):not(.icon):not(.modal__closer):not(.logo) {
	color: $green;
	transition: all 0.3s;

	&:hover {
		color: darken($green, 10%);
	}
}

h1, h2, h3, h4 {
	padding-top: 0;
	padding-bottom: 0.6em;
	color: $navy;
	font-family: $font-dosis;
	letter-spacing:1px;
	line-height: 1.1em;
	border-bottom: 1px solid #ccc; // rgba(255,255,255, 0.1);
	margin-bottom: 1.6rem;
}

h1 {
	font-size: 4rem;
	padding-bottom: 0.3em;
	color: $navy;
}

h2 {
	font-size: 3rem;
}

.modal__content ul, ul.disc {
	list-style: disc;
	padding-left: 2.4rem;
}

ul.check li {
	background: transparent url(/assets/check-circle.svg) no-repeat left 2px;
	padding-left: 3rem;
}

hr {
	border: 0;
	height: 1px;
	background: rgba(0,0,0,0.3);
	margin: 2.4rem 0;
}

footer {
	border-top:1px solid #ccc;
	background: #fff;
	text-align: center;
	font-size: 1.3rem;
	padding: 1.6rem 0.8rem;

	span {
		display: inline-block;
	}
}
