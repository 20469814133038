.button {
	@extend %link-button-format;
	display: inline-block;
	padding: 0.8rem 1.6rem;
	min-width: 15rem;
	text-align: center;
	background: $positive-interactive;
	color: white;
	transition: all 0.5s;
	border: 1px solid transparent;
	cursor: pointer;
	border-radius: 5px;

	&:hover {
		background: darken($positive-interactive, 10%);
		border-color: darken($positive-interactive, 10%);
	}

	&[disabled] {
		color: rgba(255,255,255, 0.5);
		cursor: default;
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -1.2rem;
			margin-left: -1rem;
			display: block;
			content:'';
			width: 2rem;
			height: 2rem;
			border: 2px solid transparent;
			border-top-color: white;
			border-radius: 50%;
			animation: spin .8s ease infinite;
		}
	}

}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}
