@import	"_kit/normalise", "_kit/resets-helpers"; // Standardise elements
@import url('//fonts.googleapis.com/css?family=Open+Sans:400,600');
// Google Font Reference(s)
$font-open-sans: 'Open Sans', sans-serif;
$font-dosis: 'Open Sans', sans-serif;
$font-raleway:  'Open Sans', sans-serif;

// Our variables
$base-font-family: $font-open-sans; // $font-quicksand;
$base-font-size:	1.6rem;
$base-font-weight:	400;

$base-line-height:	1.8;

$base-text-color:	#333; // rgba(255,255,255,0.7);
$background-color: 	#fff; // #000;

$wrapper-width:		120rem;
$wrapper-padding:	0 0.8rem;

$brand-color: #ffc65c;
$alt-brand-color: #7d7c7c;
$blue: #2864AE; // #4D8EFF; // #82A7BE;
$green: darken($blue, 5%);
$grey: #BEBEBE;
$navy: #000; // #fff;
$yellow: #FFF0A5;
$red: #E64C66;

%default-padding {
	padding: 4.8rem;
}

$positive-interactive: $green;

%link-button-format {
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1.5px;
}

%lead-p-format {
	color: darken($base-text-color, 20%);
	font-size: 1.5rem;
}

.wrapper {
	width: 100%;
	max-width: 120rem;
	margin: 0 auto;
	padding: 0 1.6rem;
}

.global-wrapper section {
	padding: 0;
	margin: 4.8rem 0;

	@media only screen and (min-width:770px) {
		margin: 9.6rem 0;
	}

	&:first-of-type {
		margin-top: calc(10.6rem + 6.8rem);
	}
}

section {

	p, li {
		margin: 0.8rem 0;

		&:first-of-type {
			margin-top: 0;
		}
	}

}

.cta-buttons {
	margin-top: 0.8rem;

	.button {
		margin-top: 0.8rem;
		margin-right: 1.6rem;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

@import 'global';
@import 'toaster';
@import 'lightbox';
@import 'buttons';
@import 'modal';
@import 'header-nav';
@import 'form';

.sl-overlay {
	background: #fff;
}

#about {
	padding: 0;

	.wrapper {
		max-width: 47rem;
		margin: 0 auto;
	}

	.image {
		display: block;
		width: 100%;
		margin-bottom: 0.4rem;

		img {
			width: 100%;
			border-radius: 5px;
		}
	}

	@media only screen and (min-width:770px) {
		.wrapper {
			max-width: $wrapper-width;
			display: flex;
		}

		.image {
			width: 60rem;
			margin-bottom: 0;
		}

		.content {
			padding: 2.4rem;
			padding-top: 0;
		}

	}


}

#home, #services {

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 47rem;
		margin: 0 auto;
	}

	.image {
		display: block;
		width: 100%;
		margin-bottom: 0.4rem;

		img {
			width: 100%;
			border-radius: 5px;
		}
	}

	.content {
		order: 2;
	}

	@media only screen and (min-width:770px) {
		.wrapper {
			max-width: $wrapper-width;
		}

		.image {
			width: 50%;
			margin-bottom: 0;

			img {
				display: block;
				width: 100%;
			}

		}

		.content {
			order: 0;
			width: 50%;
			padding: 2.4rem;
			padding-left: 0;
			padding-top: 0;
		}

	}

}

#home {

	@media only screen and (min-width:770px) {
		.content {
			padding: 2.4rem;
			padding-right: 0;
			padding-top: 0;
		}
	}

}

#reasons {
	padding-top: 0;

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 47rem;
		margin: 0 auto;
	}

	.box {
		width: 100%;
		background: white;
		border-radius: 5px;
		padding: 1.6rem 1.2rem;
		color: #333;
		margin-bottom: 1.6rem;
		-webkit-box-shadow: 0px 0px 20px 4px rgba(189,189,189,0.5);
		-moz-box-shadow: 0px 0px 20px 4px rgba(189,189,189,0.5);
		box-shadow: 0px 0px 20px 4px rgba(189,189,189,0.5);

		h2 {
			color: black;
		}
	}


	@media only screen and (min-width:770px) {
		.wrapper {
			max-width: $wrapper-width;
		}

		.box {
			padding: 3.2rem 2.4rem;
			width: 50%;
			margin-bottom: 0;
		}

		.content {
			padding: 2.4rem;
			padding-right: 0;
			width: 50%;
		}
	}

}

#about {

	.image {
		order :2;
	}

	.content {
		padding-left: 0;
		width: 100%;
	}

}

.gallery {
	display: flex;
	flex-wrap: wrap;

	&__item {
		width: calc(50% - 0.6rem); // 0.6rem * 2 / 2
		margin: 0.6rem;
		background: transparent url(#) no-repeat center center;
		background-size: cover;

		&:nth-child(2n+1) {
			margin-left: 0;
		}

		&:nth-child(2n) {
			margin-right: 0;
		}

		&:hover {

			.gallery__image {
				opacity: 0.5;
			}
		}

		@media only screen and (min-width:660px) {
			margin: 1.2rem;
			width: calc(25% - 1.8rem); // 1.2rem * 6 / 4

			&:nth-child(2n+1) {
				margin-left: 1.2rem;
			}

			&:nth-child(2n) {
				margin-right: 1.2rem;
			}

			&:nth-child(4n+1) {
				margin-left: 0;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}

		}

		&--hidden {
			display: none;
		}

	}
	&__image {
		display: block;
		width: 100%;
		opacity: 1;
		transition: opacity, 0.3s;
		border-radius: 5px;
	}
}

#contact {
	padding-top: 0;

	h2 {
		text-align: center;
	}

	form {
		margin: 0 auto;
		max-width: 80rem;
	}
}

.reviews {
	padding-bottom: 0.8rem;
	@media only screen and (min-width:660px) {
		padding-bottom: 0;
		// display: flex;
	}
}

.review {
	font-size: 1.8rem;
	padding: 0.8rem;
	font-style: italic;
	text-wrap: pretty;
	@media only screen and (min-width:660px) {
		max-width: 80rem;
		margin: 0 auto;
	}
	p {
		margin-bottom: 0;
	}

	cite {
		font-size: 1.4rem;
		line-height: 0.5em;
		font-style: normal;
		display: block;
		margin-top: 0.8rem;
		margin-bottom: 2.4rem;
	}

	.review-quote-mark {
		font-family: 'Times New Roman', Times, serif;
		font-size: 4.8rem;
		display: inline;
		line-height: 0;
		color: $blue;
		font-weight: bold;

		&--open {
			position: relative;
			top: 10px;
		}
		&--close {
			position: relative;
			top: 10px;
		}
	}
}
