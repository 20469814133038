.modalOpen {
	overflow: hidden;
	width: 100%;
}

.modal {
	position: fixed;
	z-index: 9999;
	top: -100%;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
  height: 100%;
  padding: 1.6rem;
	background: rgba(0,0,0,0.8) url(#) top center;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
  display: flex;
  align-items: flex-start;
	color: #333;

	&.on {
		top:0;
		opacity: 1;

		.modal__wrapper {
      opacity: 1;
		}

	}

	.modal__content {
		position: relative;
		p {
			max-width: 100%;
		}
	}

	.modal__closer {
		position: absolute;
    top: -0.8rem;
		right: 0.8rem;
		font-size: 3rem;
		cursor: pointer;
		text-decoration: none;
		color: $green;
		font-size: 4.8rem;
		border: none;
		z-index: 99999;

    @media only screen and (min-width:700px) {
      top: 0;
  		right: 3.2rem;
    }
	}

	.modal__wrapper {
		background: white;
		max-width: 90rem;
		width: 100%;
		margin: auto;
		padding: 3.2rem 1.6rem;
    opacity: 0;
		position: relative;

		@media only screen and (min-width:700px) {
			padding: 4.8rem 4.8rem;
		}

		border-radius: 5px;
    transition-timing-function: ease-in-out;
		transition: all 1s;
		box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);

	}

	h2 {
		color: black;
		border-color: rgba(0,0,0,0.3);
	}

	.cta {
		text-align: right;
		padding-top: 0;

		.button {
			max-width: 15rem;
		}
	}

}
