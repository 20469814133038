$navBreakPoint: 800px;

.global-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #fff; // #111;
	color: #333; // white;
	padding: 1.6rem 0;
	z-index: 100;

	.wrapper {
		text-align: center;
		margin: 0 auto;
		width: 100%;
		max-width: 120rem;
		flex-wrap: wrap;
		padding: 0 0.8rem;

		@media only screen and (min-width:$navBreakPoint) {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0.7rem 0;
			padding-left: 1.6rem;
		}
	}

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 0.2rem;
		background: #2752c6;
		background: linear-gradient(to right, #3d8dff 0%,#c4ecff 40%,#c4ecff 60%,#3d8dff 100%);
	}

	&:before {
		top: 0;
	}

	&:after {
		bottom: 0;
	}

}

.logo {
	display: block;
	font-family: $font-dosis;
	font-size: 2rem;
	height: 8rem;
	width: 30rem;
	line-height: 4rem;
	background: transparent url(/assets/tbs-logo-complex-01.svg) no-repeat center center;
	text-indent: -9999em;
	overflow: hidden;
	background-size: contain;
	color: $grey;
	text-align: center;
	margin: 0 auto;

	@media only screen and (min-width:$navBreakPoint) {
		margin: 0;
		height: 8rem;
		width: 40rem;
		background-position: center left;
	}

	span {
		color: #2C3D50;
	}
}

.global-nav {
	padding-top: 0.6rem;

	@media only screen and (min-width:$navBreakPoint) {
		padding-top: 0;
	}

	li {
		@extend %link-button-format;
		display: inline-block;
		padding: 0 0.4rem;

		@media only screen and (min-width:$navBreakPoint) {
			padding: 0 1.2rem;
		}

		a {
			color: $green;
			display: inline-block;
			border-bottom: 2px solid transparent;
			transition: all 0.3s;
			padding: 0 0.3rem;

			&:hover {
				color: darken($green, 10%);
				border-bottom-color: $green;
			}

			&.active {
				border-bottom-color: $green;
				color: darken($grey, 10%);
				cursor: default;
			}
		}
	}

}
