fieldset {
	border: 0;
	margin: 0;
	label {
		display: block;
	}
}

label:not([class='check-radio']), .label {
	color: #333;
	font-size: 1.1rem;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.5px;
	position: relative;
}

textarea, input:not([type='radio']) {
	border: 1px solid #ccc;
	//border-radius: 5px;
	padding: 0.6rem 1.2rem;
	border-radius: 5px;

	&:focus {
		border-color: $green;
	}
}

.required {
	display: inline-block;
	font-size: 1.6rem;
	// position: absolute;
	// top: -0.8rem;;
	// left: -0.8rem;
	color: tomato;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	max-width: 80rem;

	fieldset {
		padding: 0;
		padding-bottom: 1.6rem;
		width: 100%;

		@media only screen and (min-width:660px) {
			width: 50%;

			&.form-row--pad-left {
				padding-left: 1.6rem;
			}

			&.form-row--pad-right {
				padding-right: 1.6rem;
			}

		}

		input:not([type='radio']) {
			width: 100%;
		}

	}

}

.check-radio {
	display: inline-block;
	margin-right: 1.6rem;
	cursor: pointer;
	font-size: 1.4rem;
}

.check-radio--binary {
	display: flex;
	justify-content: space-between;

	input {
		display: none;
	}

	.check-radio {
		border: 1px solid #ccc;
		border-radius: 5px;
		height: 4.2rem;
		line-height: 3.9rem;
		width: 49%;
		margin-right: 0;
		text-align: center;
		font-size: 1.1rem;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 0.5px;
		color: darken($green, 10%);
		position: relative;

		&:hover {
			border-color: $green;
		}

		&[data-state="checked"] {
			border-color: $green;
			color: darken($green, 20%);

			i {
				position: absolute;
				bottom: 0;
				right: 0;
				width: 24px;
				height: 24px;
				background: transparent url(/assets/check.svg) no-repeat center center;
			}
		}
	}
}

.textarea {
	padding: 0;
	textarea {
		width: 100%;
		height: 20rem;
	}
}

.contactBudgetValue {
	display: none;

	&.on {
		display: block;
	}
}
